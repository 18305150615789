import React, { Component } from "react";
import Header from "../../header/header";
import axios from "axios";
import { getHeadersShared2 } from "../../utility/utility";
import { Button, Modal } from "react-bootstrap";

class FXDiscountDetails extends Component {
  state = {
    wuCountryCode: "",
    wuNumericCode: "0",
    currencyCode: "",
    isoCountryCode: "",
    accountType: "A",
    pricingCountryExt: "",
    pricingCurrency: "",
    mode: "",
    showModalFXDD: false,
    submitStatusFXDiscountDetails: null,
    submitMsgFXDiscountDetails: "",
    showFXDiscountSearchModal: false,
    isoCurrencyCode: this.props.location.isoCurrencyCode,
    showDeleteRecordConfirm: false,
    showDeleteSuccessful: false
  };

  componentDidMount() {
    this.setState({
      mode: this.props.location.mode,
    });
    if (this.props.location.mode === "insert") {
      this.setState({
        wuCountryCode: this.props.location.state.wuCountryCode,
        //wuNumericCode:this.props.location.state.wuNumericCode,
        // isoCountryCode:this.props.location.state.isoCountryCode,
        // isoCountryName:this.props.location.state.isoCountryName,
        isoCurrencyCode: this.props.location.state.isoCurrencyCode,
        isoCurrencyName: this.props.location.state.isoCurrencyName,
        status: this.props.location.state.status,
        accountType: this.props.location.state.accountType,
        // pricingCurrency:this.props.location.state.pricingCurrency,
        // pricingCountryExt:this.props.location.state.pricingCountryExt,
        iso4217Num: this.props.location.state.iso4217Num,
        isoCurrencyMajorUnit: this.props.location.state.isoCurrencyMajorUnit,
        accessGrantWriteFXDS: this.props.location.state.accessGrantWriteFXDS,
        accessGrantDeleteFXDS: this.props.location.state.accessGrantDeleteFXDS,
        operatorIdFXDS: this.props.location.state.operatorIdFXDS,
        tokenFXDS: this.props.location.state.tokenFXDS,
        // fxOpId:this.props.location.state.fxOpId,
      });
    } else {
      this.setState({
        wuCountryCode: this.props.location.state.wuCountryCode,
        wuNumericCode: this.props.location.state.wuNumericCode,
        isoCountryCode: this.props.location.state.isoCountryCode,
        isoCountryName: this.props.location.state.isoCountryName,
        isoCurrencyCode: this.props.location.state.isoCurrencyCode,
        isoCurrencyName: this.props.location.state.isoCurrencyName,
        status: this.props.location.state.status,
        accountType: this.props.location.state.accountType,
        pricingCurrency: this.props.location.state.pricingCurrency,
        pricingCountryExt: this.props.location.state.pricingCountryExt,
        iso4217Num: this.props.location.state.iso4217Num,
        isoCurrencyMajorUnit: this.props.location.state.isoCurrencyMajorUnit,
        fxOpId: this.props.location.state.fxOpId,
        accessGrantWriteFXDS: this.props.location.state.accessGrantWriteFXDS,
        accessGrantDeleteFXDS: this.props.location.state.accessGrantDeleteFXDS,
        operatorIdFXDS: this.props.location.state.operatorIdFXDS,
        tokenFXDS: this.props.location.state.tokenFXDS,
      });
    }
  }
  onChangeControlFxDiscountDetails = (e) => {
    let regex = /[^a-zA-Z0-9]/;
    let val = e.target.value;
    if (!regex.test(val)) {
      this.setState({
        [e.target.name]: val.toUpperCase(),
      });
    }
  };

  handleSubmitFXDD = () => {
    let submittedDataFXDD = {
      wuCountryCode: this.state.wuCountryCode,
      wuNumericCode: this.state.wuNumericCode,
      isoCountryCode: this.state.isoCountryCode,
      isoCountryName:
        this.state.mode === "update" ? this.state.isoCountryName : "",
      isoCurrencyCode: this.state.isoCurrencyCode,
      isoCurrencyName: this.state.isoCurrencyName,
      status: this.state.status,
      accountType: this.state.accountType,
      pricingCurrency: this.state.pricingCurrency,
      pricingCountryExt: this.state.pricingCountryExt,
      iso4217Num: this.state.iso4217Num,
      isoCurrencyMajorUnit: this.state.isoCurrencyMajorUnit,
      fxOpId:
        this.state.mode === "update"
          ? this.state.fxOpId
          : this.props.location.state.operatorIdFXDS,
    };
    if (this.state.mode === "update") {
      axios
        .put(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fxdiscount/validation`,
          submittedDataFXDD,
          {
            headers: {
              ...getHeadersShared2().headers,
              Authorization: this.state.tokenFXDS,
              userId: this.state.operatorIdFXDS,
            },
          }
        )
        .then((response) => {
          if (
            response.data.responseCode === 200 ||
            response.data.responseCode === "200"
          ) {
            this.setState({
              submitStatusFXDiscountDetails: "OK",
              submitMsgFXDiscountDetails: response.data.responseString,
            });
          } else {
            this.setState({
              submitStatusFXDiscountDetails: "Error",
              submitMsgFXDiscountDetails: response.data.responseString,
            });
          }
        });
    } else {
      if (!this.onValidateFXDD()) {
        return;
      }
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fxdiscount/validation`,
          submittedDataFXDD,
          {
            headers: {
              ...getHeadersShared2().headers,
              Authorization: this.state.tokenFXDS,
              userId: this.state.operatorIdFXDS,
            },
          }
        )
        .then((response) => {
          if (
            response.data.responseCode === 200 ||
            response.data.responseCode === "200"
          ) {
            this.setState({
              //showModalFXDD: true,
              mode: 'update',
              submitStatusFXDiscountDetails: "OK",
              submitMsgFXDiscountDetails: response.data.responseString,
            });
          } else {
            this.setState({
              submitStatusFXDiscountDetails: "Error",
              submitMsgFXDiscountDetails: response.data.responseString,
            });
          }
        });
    }
  };

  onHandleDeleteFXDD = () => {
    let submittedDataFXDD = {
      wuCountryCode: this.state.wuCountryCode,
      isoCurrencyCode: this.state.isoCurrencyCode,
      accountType: this.state.accountType,
    }
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fxdiscount/delete`,
        submittedDataFXDD,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization: this.state.tokenFXDS,
            userId: this.state.operatorIdFXDS,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            showDeleteRecordConfirm: false,
            showDeleteSuccessful: true
          });
        } else {
          this.setState({
            submitStatusFXDiscountDetails: "ERROR",
            submitMsgFXDiscountDetails: response.data.responseString,
            showDeleteRecordConfirm: false
          });
        }
      });
  };

  onHandleInsertFXDD = () => {
    this.setState({ mode: "update" });
  };
  onValidateFXDD = () => {
    if (this.state.wuNumericCode === "") {
      this.setState({
        submitStatusFXDiscountDetails: "Error",
        submitMsgFXDiscountDetails: "Please Enter WU Numeric Code",
      });
      return false;
    }
    if (this.state.wuNumericCode <= 0 || this.state.wuNumericCode <= "0") {
      this.setState({
        submitStatusFXDiscountDetails: "Error",
        submitMsgFXDiscountDetails: "WU Numeric Code Must Be Greater Than 0",
      });
      return false;
    }
    if (this.state.isoCountryCode === "") {
      this.setState({
        submitStatusFXDiscountDetails: "Error",
        submitMsgFXDiscountDetails: "Please Enter ISO Country Code",
      });
      return false;
    }
    if (
      this.state.pricingCurrency === "" ||
      this.state.pricingCurrency === null
    ) {
      this.setState({
        submitStatusFXDiscountDetails: "Error",
        submitMsgFXDiscountDetails: "Please Enter WU Pricing Currency Value",
      });
      return false;
    }
    if (
      this.state.pricingCountryExt === "" ||
      this.state.pricingCountryExt === null
    ) {
      this.setState({
        submitStatusFXDiscountDetails: "Error",
        submitMsgFXDiscountDetails: "Please Enter WU Pricing Country Ext Value",
      });
      return false;
    }
    if (
      this.state.wuNumericCode !== "" &&
      /^\d+(\.\d+)?$/.test(this.state.wuNumericCode) === false
    ) {
      this.setState({
        submitStatusFXDiscountDetails: "Error",
        submitMsgFXDiscountDetails: "WU Numeric Code Is Invalid",
      });
      return false;
    }

    return true;
  };

  getFxValidationDetailsDeleteModal = () => {
    return (
        <div>
            <Modal
                show={this.state.showDeleteRecordConfirm}
                animation={true}
                size="md"
                className="shadow-lg border"
            >
                <Modal.Header className="bg-dark text-white text-center py-1">
                    <Modal.Title className="text-center">
                        <h5 className="modal-header-custom">
                            Are You Sure, You Want To Delete The Record With WU Country Code: {this.state.wuCountryCode}?
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer className="py-1 d-flex justify-content-center">
                    <div>
                        <Button
                            variant="warning"
                            onClick={() =>
                                this.setState({
                                    showDeleteRecordConfirm: false,
                                })
                            }
                        >
                            No
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="warning"
                            className="mx-2 px-3"
                            onClick={this.onHandleDeleteFXDD}
                        >
                            Yes
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

getFxValidationDetailsDeleteConfirmationModal = () => {
  return (
      <div>
          <Modal
              show={this.state.showDeleteSuccessful}
              animation={true}
              size="md"
              className="shadow-lg border"
          >
              <Modal.Header className="bg-dark text-white text-center py-1">
                  <Modal.Title className="text-center">
                      <h5 className="modal-header-custom">
                          Record Deleted Successfully
                      </h5>
                  </Modal.Title>
              </Modal.Header>
              <Modal.Footer className="py-1 d-flex justify-content-center">
                  <div>
                      <Button
                          variant="warning"
                          onClick={() =>
                              this.props.history.push("/fx-discount-search")
                          }
                      >
                          OK
                      </Button>
                  </div>
              </Modal.Footer>
          </Modal>
      </div>
  );
};

  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid row">
          <div className="col-lg-3"></div>
          <div className="card-body col-lg-6">
            <h4 className="card-title main-heading ">
              F/X Discount Validation Database Maintenance
            </h4>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                F/X DISCOUNT DETAILS
              </div>
              <div className="card-body card-body-custom">
                <div className="form-group row">
                  <div className="col-lg">
                    <label htmlFor="wuCountryCode" className="font-weight-bold">
                      WU Country Code
                    </label>
                    <input
                      type="text"
                      value={this.state.wuCountryCode}
                      className="form-control form-control-sm"
                      name="wuCountryCode"
                      disabled
                      data-test="text-wuCountryCode"
                      placeholder="WU Country Code"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="wuNumericCode" className="font-weight-bold">
                      WU Numeric Code
                    </label>
                    <input
                      type="text"
                      value={this.state.wuNumericCode}
                      maxLength="3"
                      className="form-control form-control-sm"
                      name="wuNumericCode"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountDetails(e);
                      }}
                      disabled={
                        this.state.mode === "display" ||
                        this.state.mode === "update"
                      }
                      data-test="text-wuNumericCode"
                      placeholder="WU Numeric Code"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label
                      htmlFor="isoCountryCode"
                      className="font-weight-bold"
                    >
                      ISO Country Code
                    </label>
                    <input
                      type="text"
                      value={this.state.isoCountryCode}
                      maxLength="3"
                      className="form-control form-control-sm"
                      name="isoCountryCode"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountDetails(e);
                      }}
                      data-test="text-isoCountryCode"
                      placeholder="ISO Country Code"
                      disabled={
                        this.state.mode === "display" ||
                        this.state.mode === "update"
                      }
                    />
                  </div>
                  {this.state.mode === "insert" ? null : (
                    <div className="col-lg">
                      <label
                        htmlFor="isoCountryName"
                        className="font-weight-bold"
                      >
                        ISO Country Name
                      </label>
                      <input
                        type="text"
                        value={this.state.isoCountryName}
                        maxLength="3"
                        className="form-control form-control-sm"
                        name="isoCountryName"
                        onChange={(e) => {
                          this.onChangeControlFxDiscountDetails(e);
                        }}
                        data-test="text-isoCountryName"
                        placeholder="ISO Country Name"
                        disabled
                      />
                    </div>
                  )}
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label
                      htmlFor="isoCurrencyCode"
                      className="font-weight-bold"
                    >
                      ISO Currency Code
                    </label>
                    <input
                      type="text"
                      value={this.state.isoCurrencyCode}
                      maxLength="3"
                      className="form-control form-control-sm"
                      name="isoCurrencyCode"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountDetails(e);
                      }}
                      disabled
                      data-test="text-isoCurrencyCode"
                      placeholder="ISO Currency Code"
                    />
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="isoCurrencyName"
                      className="font-weight-bold"
                    >
                      ISO Currency Name
                    </label>
                    <input
                      type="text"
                      value={this.state.isoCurrencyName}
                      maxLength="3"
                      className="form-control form-control-sm"
                      name="isoCurrencyName"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountDetails(e);
                      }}
                      disabled
                      data-test="text-isoCurrencyName"
                      placeholder="ISO Currency Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <legend className="col-form-label font-weight-bold">
                      Status
                    </legend>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        value="A"
                        onChange={(e) => {
                          this.onChangeControlFxDiscountDetails(e);
                        }}
                        checked={this.state.status === "A"}
                        data-test="text-status-active"
                        disabled={
                          this.state.mode === "display" ||
                          this.state.mode === "insert"
                        }
                      />
                      <label className="form-check-label font-weight-bold">
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        value="D"
                        onChange={(e) => {
                          this.onChangeControlFxDiscountDetails(e);
                        }}
                        checked={this.state.status === "D"}
                        data-test="text-status-delete"
                        disabled={
                          this.state.mode === "display" ||
                          this.state.mode === "insert"
                        }
                      />
                      <label className="form-check-label font-weight-bold">
                        Inactive
                      </label>
                    </div>
                  </div>{" "}
                  <div className="col-lg">
                    <label htmlFor="accountType" className="font-weight-bold">
                      Account Type
                    </label>
                    <input
                      type="text"
                      value={this.state.accountType}
                      maxLength="3"
                      className="form-control form-control-sm"
                      name="accountType"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountDetails(e);
                      }}
                      data-test="text-accountType"
                      placeholder="Account Type"
                      disabled
                    />
                  </div>
                </div>
                <div className="formm-group row">
                  <div className="col-lg">
                    <label
                      htmlFor="pricingCurrency"
                      className="font-weight-bold"
                    >
                      Pricing Currency
                    </label>
                    <input
                      type="text"
                      value={this.state.pricingCurrency}
                      maxLength="5"
                      className="form-control form-control-sm"
                      name="pricingCurrency"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountDetails(e);
                      }}
                      data-test="text-pricingCurrency"
                      placeholder="Pricing Currency"
                      disabled={
                        this.state.mode === "display"
                      }
                    />
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="pricingCountryExt"
                      className="font-weight-bold"
                    >
                      Pricing Country Ext
                    </label>
                    <input
                      type="text"
                      value={this.state.pricingCountryExt}
                      maxLength="2"
                      className="form-control form-control-sm"
                      name="pricingCountryExt"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountDetails(e);
                      }}
                      data-test="text-pricingCountryExt"
                      placeholder="Pricing Country Ext"
                      disabled={
                        this.state.mode === "display"
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg text-center mar-bottom5 mar-top20">
                    {this.state.mode === "display" ? null : (
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        data-test="button-submit"
                        onClick={this.handleSubmitFXDD}
                        disabled={!this.state.accessGrantWriteFXDS}
                      >
                        SUBMIT
                      </button>
                    )}
                     {(this.state.accessGrantDeleteFXDS) && (this.state.mode === "display" ? null : (
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        data-test="button-submit"
                        onClick={() => {
                          this.setState({
                              showDeleteRecordConfirm: true,
                          });
                      }}
                        disabled={!this.state.accessGrantDeleteFXDS}
                      >
                        DELETE
                      </button>
                    ))}
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-exit"
                      onClick={() => {
                        this.props.history.push("/fx-discount-search");
                      }}
                    //   disabled={!this.state.accessGrantQCDM}
                    >
                      EXIT
                    </button>
                  </div>
                </div>
                {this.getFxValidationDetailsDeleteModal()}
                {this.getFxValidationDetailsDeleteConfirmationModal()}
                <div className="form-group row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    {this.state.submitStatusFXDiscountDetails === "OK" && (
                      <div className="alert alert-success alert-padding notification text-center">
                        {this.state.submitMsgFXDiscountDetails}
                      </div>
                    )}
                    {this.state.submitStatusFXDiscountDetails === "Error" && (
                      <div className="alert alert-danger alert-padding notification text-center">
                        {this.state.submitMsgFXDiscountDetails}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default FXDiscountDetails;
